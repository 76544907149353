import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Button from 'react-bootstrap/Button';

function App() {
  const [forecasts, setForecasts] = useState(Array<any>);
  const url = "/WeatherForecast";

  useEffect(() => {
    fetch(url)
        .then(res => res.json())
        .then(data => setForecasts(data))
  }, [])

  function renderForecastsTable() {
    return (
      <table aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
            <tr key={forecast.date}>
              <td>{forecast.date}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  const forecastContent = renderForecastsTable();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <Button>Show Toast</Button>
        <div>
          <h1 id="tabelLabel" >Weather forecast</h1>
          <p>This component demonstrates fetching data from the server.</p>
          {forecastContent}
        </div>
      </header>
    </div>
  );
}

export default App;
